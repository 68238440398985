import { zodResolver } from '@hookform/resolvers/zod'
import * as Tabs from '@radix-ui/react-tabs'
import { useMutation, useQuery } from '@tanstack/react-query'
import _ from 'lodash'
import { ArrowUpToLine, Redo2, RefreshCcw, Search, X } from 'lucide-react'
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useMediaQuery } from 'react-responsive'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { toast } from 'sonner'
import { z } from 'zod'

import { excludeDevice } from '../../../api/exclude-device'
import { getDevices, GetDevicesResponse } from '../../../api/get-devices'
import { getRoles } from '../../../api/get-roles'
import { getTransmissors } from '../../../api/get-transmissors'
import { migrateDevice } from '../../../api/migrate-device'
import { registerDevices } from '../../../api/register-devices'
import { synchronizeDevice } from '../../../api/synchronize-device'
import { Button } from '../../../components/Button'
import { Checkbox } from '../../../components/Form/Checkbox'
import * as Input from '../../../components/Form/Input'
import * as Select from '../../../components/Form/Select'
import { Textarea } from '../../../components/Form/Textarea'
import { Pagination } from '../../../components/Pagination'
import { TabItem } from '../../../components/TabItem'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '../../../components/Table'
import { Tag } from '../../../components/Tag'
import { ThemeToggle } from '../../../components/ThemeToggle'
import { Toast } from '../../../components/Toast'
import { queryClient } from '../../../lib/react-query'
import { vlans } from '../../../lib/vlans'
import { DevicesTableSkeleton } from './devices-table-skeleton'

const migratedeviceSchema = z.object({
  transmissor: z.string(),
  slot: z.string(),
  pon: z.string(),
  cto: z.string().min(8),
})

type MigrateDeviceSchema = z.infer<typeof migratedeviceSchema>

export function IXC() {
  const { data: roles } = useQuery({
    queryKey: ['roles'],
    queryFn: getRoles,
  })

  const [searchParams, setSearchParams] = useSearchParams()

  const perPage = z.coerce.number().parse(searchParams.get('amount') ?? '16')

  const pageIndex = z.coerce
    .number()
    .transform((page) => page - 1)
    .parse(searchParams.get('page') ?? '1')

  function handlePaginate(pageIndex: number) {
    setSearchParams((state) => {
      state.set('page', (pageIndex + 1).toString())

      return state
    })
  }

  const navigate = useNavigate()

  const isMobile = useMediaQuery({ maxWidth: 768 })

  const [allChecked, setAllChecked] = useState(false)
  const [devices, setDevices] = useState<string[]>([])

  const [rows, setRows] = useState('')

  const { data: result, isLoading } = useQuery({
    queryKey: ['get-devices', pageIndex, perPage],
    queryFn: () =>
      getDevices({
        pageIndex,
        perPage,
      }),
    refetchOnWindowFocus: false,
  })

  const { data: transmissorsResult } = useQuery({
    queryKey: ['get-transmissors'],
    queryFn: getTransmissors,
    refetchOnWindowFocus: false,
  })

  const { mutateAsync: excludeDeviceFn, isPending: isExcludePending } =
    useMutation({
      mutationKey: ['exclude-devices'],
      mutationFn: excludeDevice,
      onSuccess: async (_, variables) => {
        updateDevice(variables.deviceId, {
          status: {
            name: 'Desprovisionado',
            type: 'success',
          },
        })
      },
      onError: async (_, variables) => {
        updateDevice(variables.deviceId, {
          status: {
            name: 'ONU não está provisionada',
            type: 'error',
          },
        })
      },
    })

  const { mutateAsync: migrateDeviceFn, isPending: isMigratePending } =
    useMutation({
      mutationKey: ['migrate-devices'],
      mutationFn: migrateDevice,
      onSuccess: async (data, variables) => {
        updateDevice(variables.deviceId, {
          ...data,
          status: {
            name: 'Migrado',
            type: 'success',
          },
        })
      },
      onError: async (
        error: { response: { data: { message: string; deviceId: string } } },
        variables,
      ) => {
        updateDevice(variables.deviceId, {
          status: {
            name: 'Erro ao migrar',
            type: 'error',
          },
        })
        toast.custom((t) => (
          <Toast
            t={t}
            title={`${error.response.data.deviceId}: ${error.response.data.message}`}
            variant="error"
          />
        ))
      },
    })

  const { mutateAsync: synchronizeDeviceFn, isPending: isSynchronizePending } =
    useMutation({
      mutationKey: ['synchronize-devices'],
      mutationFn: synchronizeDevice,
      onSuccess: async (data, variables) => {
        updateDevice(variables.deviceId, {
          ...data,
          status: {
            name: 'Sincronizado',
            type: 'success',
          },
        })
      },
      onError: async (
        error: { response: { data: { message: string; deviceId: string } } },
        variables,
      ) => {
        updateDevice(variables.deviceId, {
          status: {
            name: 'Erro ao sincronizar',
            type: 'error',
          },
        })
        toast.custom((t) => (
          <Toast
            t={t}
            title={`${error.response.data.deviceId}: ${error.response.data.message}`}
            variant="error"
          />
        ))
      },
    })

  const { mutateAsync: registerDevicesFn, isPending: isRegisteringDevices } =
    useMutation({
      mutationKey: ['register-devices'],
      mutationFn: registerDevices,
      onSuccess: async (data) => {
        queryClient.refetchQueries({ queryKey: ['get-devices'] })
        setRows('')

        if (data.success[0]) {
          for (const success of data.success) {
            toast.custom((t) => (
              <Toast
                t={t}
                title={`${success.mac}: Dispositivo registrado com sucesso!`}
                variant="success"
              />
            ))
          }
        }

        if (data.errors[0]) {
          for (const error of data.errors) {
            toast.custom((t) => (
              <Toast
                t={t}
                title={`${error.mac}: ${error.message}`}
                variant="error"
              />
            ))
          }
        }
      },
    })

  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm<MigrateDeviceSchema>({
    resolver: zodResolver(migratedeviceSchema),
  })

  function updateDevice(
    deviceId: string,
    newDevice: Partial<GetDevicesResponse['devices'][number]>,
  ) {
    const devicesListingCache = queryClient.getQueriesData<GetDevicesResponse>({
      queryKey: ['get-devices'],
    })

    devicesListingCache.forEach(([cacheKey, cached]) => {
      if (!cached) {
        return
      }

      queryClient.setQueryData<GetDevicesResponse>(cacheKey, {
        ...cached,
        devices: cached.devices.map((originalDevice) => {
          if (originalDevice.id !== deviceId) {
            return originalDevice
          }

          return {
            ...originalDevice,
            ...newDevice,
          }
        }),
      })
    })
  }

  function handleMigrateDevices(data: MigrateDeviceSchema) {
    const { transmissor, slot, pon, cto } = data

    const vlan = vlans.find((vlan) => vlan.transmissorId === transmissor)?.vlan

    if (!vlan) return

    for (const device of devices) {
      updateDevice(device, {
        status: {
          name: 'Migrando',
          type: 'progress',
        },
      })
      migrateDeviceFn({
        transmissor,
        slot,
        pon,
        cto,
        vlan,
        deviceId: device,
      })
    }
  }

  const isDoingSomeAction = result?.devices.some((device) => device.status)

  function handleInsertDevices() {
    const lines = rows.split('\n')

    const mac = lines.map((line) => line.split('  ')[4]).filter(Boolean)

    registerDevicesFn({
      devices: mac,
    })
  }

  function handleSynchronizeDevice() {
    for (const device of devices) {
      updateDevice(device, {
        status: {
          name: 'Sincronizando',
          type: 'progress',
        },
      })
      synchronizeDeviceFn({
        deviceId: device,
      })
    }
  }

  function handleExcludeDevice() {
    for (const device of devices) {
      updateDevice(device, {
        status: {
          name: 'Desprovisionando',
          type: 'progress',
        },
      })
      excludeDeviceFn({
        deviceId: device,
      })
    }
  }

  useEffect(() => {
    if (!result) return

    if (allChecked) {
      setDevices(result.devices.map((device) => device.id))
    } else {
      setDevices([])
    }
  }, [allChecked])

  if (roles) {
    const hasPermissions = roles.teams.some((team) =>
      team.permissions.some(
        (permission) => permission.name === 'integrationIXC',
      ),
    )

    if (!hasPermissions) {
      navigate('/')
      return null
    }
  }

  return (
    <div>
      <div className="flex w-full flex-col justify-between px-4 md:flex-row md:px-0">
        <h1 className="text-3xl font-semibold text-gray-700 dark:text-gray-25">
          IXCSoft
        </h1>
        <div className="ml-auto">
          <ThemeToggle />
        </div>
      </div>
      <div className="px-4 md:px-0">
        {isMobile ? (
          <Select.Root defaultValue="slot">
            <Select.Trigger className="mb-8 mt-6">
              <Select.Value />
            </Select.Trigger>

            <Select.Content>
              <Select.Item value="slot">
                <Select.ItemText>SLOT/PON</Select.ItemText>
              </Select.Item>
            </Select.Content>
          </Select.Root>
        ) : (
          <Tabs.Root
            defaultValue="slot"
            className="hidden border-b border-gray-100 dark:border-gray-750 md:block"
          >
            <Tabs.List className="relative mt-6 flex w-full items-center gap-4 focus:shadow-none">
              <TabItem
                value="slot"
                title="SLOT/PON"
                isSelected
                layoutId="ixcsoft"
              />
            </Tabs.List>
          </Tabs.Root>
        )}
      </div>
      <div className="gap-8 md:grid md:grid-cols-[1fr_24px_1fr]">
        <div className="px-4 md:px-0 md:py-8">
          <h1 className="text-lg font-semibold text-gray-900 dark:text-gray-25">
            Origem
          </h1>
          <div className="mt-6 flex flex-col gap-1.5">
            <label className="text-sm font-medium text-gray-700 dark:text-gray-375">
              MACs
            </label>
            <Textarea
              value={rows}
              onChange={(e) => setRows(e.target.value)}
              placeholder="Insira os MACs a serem migrados."
            />
            <div className="ml-auto mt-4 flex gap-3">
              <Button
                onClick={handleSynchronizeDevice}
                disabled={isSynchronizePending || !devices.length}
                variant="outline"
                className="flex items-center justify-center gap-1.5"
              >
                <RefreshCcw className="h-5 w-5" /> Sincronizar
              </Button>
              <Button
                onClick={handleInsertDevices}
                disabled={isRegisteringDevices || !rows}
                className="flex items-center justify-center gap-1.5"
              >
                <ArrowUpToLine className="h-5 w-5" /> Inserir
              </Button>
            </div>
          </div>
        </div>
        <div className="px-4 md:flex md:justify-center md:px-0">
          <div className="my-8 h-px w-full bg-gray-100 dark:bg-gray-750 md:my-0 md:h-full md:w-px" />
        </div>
        <form
          onSubmit={handleSubmit(handleMigrateDevices)}
          className="flex flex-col px-4 md:px-0 md:py-8"
        >
          <h1 className="text-lg font-semibold text-gray-900 dark:text-gray-25">
            Destino
          </h1>
          <div className="mt-6 gap-4 md:grid md:grid-cols-2">
            <div className="flex flex-col gap-1.5 md:col-span-2">
              <label className="text-sm font-medium text-gray-700 dark:text-gray-375">
                Transmissor*
              </label>
              <Controller
                name="transmissor"
                control={control}
                render={({ field }) => (
                  <Select.Root
                    {...field}
                    value={field.value}
                    onValueChange={field.onChange}
                  >
                    <Select.Trigger>
                      <Select.Value placeholder="Selecione o transmissor" />
                    </Select.Trigger>

                    <Select.Content>
                      {transmissorsResult &&
                        transmissorsResult.transmissors.map(
                          ({ id, descricao }) => (
                            <Select.Item key={id} value={id}>
                              <Select.ItemText>{descricao}</Select.ItemText>
                            </Select.Item>
                          ),
                        )}
                    </Select.Content>
                  </Select.Root>
                )}
              />
            </div>
            <div className="flex flex-col gap-1.5">
              <label className="text-sm font-medium text-gray-700 dark:text-gray-375">
                SLOT*
              </label>
              <Controller
                name="slot"
                control={control}
                render={({ field }) => (
                  <Input.Root>
                    <Input.Control {...field} placeholder="0-99" />
                  </Input.Root>
                )}
              />
            </div>
            <div className="mt-6 flex flex-col gap-1.5 md:mt-0">
              <label className="text-sm font-medium text-gray-700 dark:text-gray-375">
                PON*
              </label>
              <Controller
                name="pon"
                control={control}
                render={({ field }) => (
                  <Input.Root>
                    <Input.Control {...field} placeholder="0-99" />
                  </Input.Root>
                )}
              />
            </div>
          </div>
          <div className="mt-6 flex flex-col gap-1.5">
            <label className="text-sm font-medium text-gray-700 dark:text-gray-375">
              CTO*
            </label>
            <Controller
              name="cto"
              control={control}
              render={({ field }) => (
                <Input.Root>
                  <Input.Control {...field} placeholder="IDN-00/00" />
                </Input.Root>
              )}
            />
          </div>
          <div className="ml-auto mt-6 flex gap-3">
            <Button
              type="button"
              variant="outline"
              onClick={handleExcludeDevice}
              className="flex items-center justify-center gap-1.5"
              disabled={!devices.length || isMigratePending || isExcludePending}
            >
              <X className="h-5 w-5" /> Desprovisionar
            </Button>
            <Button
              type="submit"
              className="flex items-center justify-center gap-1.5"
              disabled={
                !devices.length ||
                !isValid ||
                isMigratePending ||
                isExcludePending
              }
            >
              <Redo2 className="h-5 w-5" /> Migrar
            </Button>
          </div>
        </form>
      </div>
      <Table className="mt-6">
        <TableHeader>
          <TableHead>
            <div className="flex items-center gap-3">
              <Checkbox
                id="all"
                disabled={!result?.devices.length}
                onCheckedChange={(checked: boolean) => setAllChecked(checked)}
              />
              <span className="leading-tight">ID</span>
            </div>
          </TableHead>
          <TableHead className="leading-tight">Transmissor</TableHead>
          <TableHead className="leading-tight">SLOT</TableHead>
          <TableHead className="leading-tight">PON</TableHead>
          <TableHead className="leading-tight">Nome</TableHead>
          <TableHead className="leading-tight">MAC</TableHead>
          <TableHead className="leading-tight">Inserido por</TableHead>
          <TableHead>{isDoingSomeAction && 'Status'}</TableHead>
        </TableHeader>
        <TableBody>
          {result &&
            result.devices.map((device) => (
              <Row
                key={device.id}
                device={device}
                allChecked={allChecked}
                setDevices={setDevices}
              />
            ))}
          {isLoading && <DevicesTableSkeleton perPage={8} />}
        </TableBody>
      </Table>
      {_.isEmpty(result?.devices) && !isLoading && (
        <div className="flex w-full flex-col items-center justify-center border-x border-b border-gray-100 px-8 py-10 pb-12 dark:border-gray-750">
          <div className="rounded-lg border border-gray-100 p-3 shadow-xs dark:border-gray-750">
            <Search className="h-6 w-6 text-gray-700 dark:text-gray-100" />
          </div>
          <h1 className="mt-4 font-semibold text-gray-900 dark:text-gray-25">
            Nenhum dispositivo encontrado
          </h1>
          <p className="mt-1 text-center text-sm text-gray-600 dark:text-gray-375">
            Sua pesquisa não encontrou nenhum dispositivo. <br />
          </p>
        </div>
      )}
      {result && (
        <Pagination
          onPageChange={handlePaginate}
          pageIndex={result.meta.pageIndex}
          totalCount={result.meta.totalCount}
          totalInPage={result.devices.length}
          perPage={result.meta.perPage}
        />
      )}
    </div>
  )
}

type Device = GetDevicesResponse['devices'][number]

interface DeviceProps extends Device {
  status?: {
    name: string
    type: 'progress' | 'error' | 'success'
  }
}

interface RowProps {
  device: DeviceProps
  allChecked: boolean
  setDevices: React.Dispatch<React.SetStateAction<string[]>>
}

export function Row({ device, allChecked, setDevices }: RowProps) {
  const [checked, setChecked] = useState(false)

  useEffect(() => {
    setChecked(allChecked)
  }, [allChecked])

  return (
    <TableRow key={device.id}>
      <TableCell data-state={checked}>
        <div className="flex items-center gap-3">
          <Checkbox
            id={device.id}
            checked={checked}
            onCheckedChange={(checked: boolean) => {
              if (checked) {
                setDevices((state) => [...state, device.id])
              } else {
                setDevices((state) =>
                  state.filter((item) => item !== device.id),
                )
              }
              setChecked(checked)
            }}
          />
          <span>{device.id}</span>
        </div>
      </TableCell>
      <TableCell data-state={checked}>{device.transmissor}</TableCell>
      <TableCell data-state={checked}>{device.slot}</TableCell>
      <TableCell data-state={checked}>{device.pon}</TableCell>
      <TableCell data-state={checked}>{device.name}</TableCell>
      <TableCell data-state={checked}>{device.mac}</TableCell>
      <TableCell data-state={checked}>{device.createdBy.name}</TableCell>
      <TableCell data-state={checked}>
        {device.status && (
          <Tag
            className="flex items-center"
            variant={
              device.status.type === 'progress'
                ? 'yellow'
                : device.status.type === 'success'
                  ? 'green'
                  : device.status.type === 'error'
                    ? 'red'
                    : 'purple'
            }
          >
            {device.status.name}
          </Tag>
        )}
      </TableCell>
    </TableRow>
  )
}
