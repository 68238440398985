import { api } from '../lib/axios'

export interface MigrateDeviceBody {
  deviceId: string
  transmissor: string
  slot: string
  pon: string
  cto: string
  vlan: string
}

export async function migrateDevice({
  deviceId,
  transmissor,
  slot,
  pon,
  cto,
  vlan,
}: MigrateDeviceBody) {
  const response = await api.post('/devices/migrate', {
    deviceId,
    transmissor,
    slot,
    pon,
    cto,
    vlan,
  })

  return response.data
}
